import { get, post, put, del, upload } from '@/utils/request'

export default {
  add(data) {
    return post(`/rest/order`, data)
  },
  update(uuid, data) {
    return put(`/rest/order/${uuid}`, data)
  },
  info(uuid) {
    return get(`/rest/order/${uuid}`)
  },
  del(uuid) {
    return del(`/rest/order?order_id=${uuid}`)
  },
  getList(params = {}) {
    return get(`/rest/order`, params)
  },
  import(body) {
    return upload(`/rest/order/import`, body)
  },
  export(params = {}) {
    return get(`/rest/order/export`, params, {
      responseType: 'blob'
    })
  },
  batch(body) {
    return put(`/rest/order/batch`, body)
  },
  review(order_id, status, body = {}) {
    return put(`/rest/order/review/${order_id}?status=${status}`, body)
  },
  batchReview(body, status) {
    return put(`/rest/order/review?status=${status}`, body)
  },
  checkout(body) {
    return post(`/rest/order/dipm/fee/settlement`, body)
  },
  dipmFeeTotal(params = {}) {
    return get(`/rest/order/dipm/fee/total`, params)
  },
  dipmFeeLog(params = {}) {
    return get(`/rest/order/dipm/fee/log`, params)
  },
  dipmFeeLogExport(id, type = 1) {
    return get(`/rest/order/log/export/${id}`, { type })
  },
  checkoutDelivery(body) {
    return post(`/rest/order/delivery/fee/settlement`, body)
  },
  deliveryFeeTotal(params = {}) {
    return get(`/rest/order/delivery/fee/total`, params)
  },
  deliveryFeeLog(params = {}) {
    return get(`/rest/order/delivery/fee/log`, params)
  },
  sync(body) {
    return post(`/rest/post/nord/edi`, body, {
      timeout: 500000
    })
  },
  batchPrint(body, type) {
    return post(`/rest/post/nord/pdfs/export${type ? ('?type=' + type) : ''}`, body)
  },
  removePnOrder(order_id, type) {
    return del(`/rest/order/pn?order_id=${order_id}&type=${type}`)
  },
  handleFinish(order_id) {
    return put(`/rest/order/finish/${order_id}`)
  },
}
