import { get, post, put, del } from '@/utils/request'

export default {
  add(data) {
    return post(`/rest/order/cost`, data)
  },
  update(uuid, data) {
    return put(`/rest/order/cost/${uuid}`, data)
  },
  del(uuid) {
    return del(`/rest/order/cost?type_id=${uuid}`)
  },
  getList(params = {}) {
    return get(`/rest/order/cost`, params)
  },
}
